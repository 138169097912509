import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";

const Packages = () => {
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      const Packages = query(
        collection(db, "Packages"),
        orderBy("date", "desc")
      );
      const querySnapshot = await getDocs(Packages);
      querySnapshot.forEach((doc) => {
        topList.push({ id: doc.id, ...doc.data() });
      });

      setPackages(topList);
    };
    getData();
  }, []);
  return (
    <>
      <Hero hero="roomsHero">
        <Banner title="Explore Our Packages">
          <Link to="/" className="btn-primary">
            Return Home
          </Link>
        </Banner>
      </Hero>
      <br />
      <header class="section-header">
        <h3>Our Packages</h3>
        <div class="divider"></div>
        <p>
          Our packages are designed to provide uninterrupted power supply for
          homes and offices through our appropriately sized Inverter, battery
          bank and solar array with special consideration for appliance to be
          powered and hours of usage. Kindly go through our packages below to
          find a suitable package that fits into your budget or energy
          requirement.
        </p>
      </header>
      <section className="package-container">
        {/* <div class="overview-content">
          <ul class="features-list">
            {packages.map((item, index) => (
              <li key={index}>
                <Link to={"/packages/" + item.id}>
                  <span>{item.packages}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <br /> */}
        <div class="row">
          <a
            style={{ color: "black" }}
            href="/packages/lithium-battery"
            className="card col-1"
          >
            <h2>Lithium battery packages</h2>
          </a>
          <a
            style={{ color: "black" }}
            href="/packages/tubular-battery"
            className="card col-1"
          >
            <h2>Tubular battery packages</h2>
          </a>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <center>
          <p>
            Didn't find what you need here? <a href="/contact"> Contact us</a>{" "}
            for a custom system.
          </p>
        </center>
      </section>
    </>
  );
};

export default Packages;
