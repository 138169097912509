import React, { useEffect, useState } from "react";
import "./App.css";
import "./pages/Admin.css";
import Home from "./pages/Home";
import Error from "./pages/Error";
import About from "./pages/About";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import Quote from "./pages/Quote";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import SignIn from "./pages/SignIn";
import FormSuccess from "./pages/FormSuccess";
import Services from "./pages/Services";
import EnergyLoadChart from "./pages/EnergyLoadChart";
import Recruitment from "./pages/Recruitment";
import Contact from "./pages/Contact";
import Packages from "./pages/Packages";
import AdminAddPackage from "./pages/AdminAddPackage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminAllPackages from "./pages/AdminAllPackages";
import AdminPodcastEdit from "./pages/AdminPackageEdit";
import AdminAddPackageMidMax from "./pages/AdminAddPackageMidMax";
import AdminEditPackageMidMax from "./pages/AdminEditPackageMidMax";
import { auth } from "./firebase";
import Login from "./pages/Login";
import PackageDetails from "./pages/Packages/PackageDetails";
import Gallery from "./pages/Gallery";
import AdminGallery from "./pages/AdminGallery";
import Lithium from "./pages/Packages/Lithium";
import Tubular from "./pages/Packages/Tubular";

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
  }, []);
  return (
    <>
      <Navbar />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/success" element={<FormSuccess />} />
        <Route path="/services" element={<Services />} />
        <Route path="/recruitment" element={<Recruitment />} />
        <Route path="/energy-load-chart" element={<EnergyLoadChart />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/packages/lithium-battery" element={<Lithium />} />
        <Route path="/packages/tubular-battery" element={<Tubular />} />
        <Route path="/administrator/register" element={<SignIn />} />
        <Route path="/administrator/login" element={<Login />} />
        <Route path="/packages/:id" element={<PackageDetails />} />
        <Route
          path="/administrator"
          element={<AdminAllPackages />}
          user={user}
        />
        <Route
          path="/administrator/gallery"
          element={<AdminGallery />}
          user={user}
        />

        <Route
          path="/administrator/create-package"
          element={<AdminAddPackage />}
          user={user}
        />

        <Route
          path="/administrator/create-package-mid-max"
          element={<AdminAddPackageMidMax />}
          user={user}
        />

        <Route
          path="/administrator/edit/:id"
          element={<AdminPodcastEdit />}
          user={user}
        />

        <Route
          path="/administrator/edit-mid-max/:id"
          element={<AdminEditPackageMidMax />}
          user={user}
        />

        <Route
          path="/administrator/packages"
          element={<AdminAllPackages />}
          user={user}
        />

        <Route element={Error} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
