import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        navigate("/administrator/packages");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ..
      });
  };
  return (
    <div>
      <div className="container empty-space">
        <div className="form-container">
          <h2 style={{ textAlign: "center" }}>Admin Login </h2>
          <br />
          <form className="form-content" onSubmit={handleSubmit}>
            <label htmlFor="email">Email Addres</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              type="email"
            />
            <label htmlFor="password">Email Addres</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              type="password"
            />
            <button className="btn-secondary" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
