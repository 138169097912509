import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyCi6zW381JbHgIP2bk86ZdXobXVCdqNrKE",
  authDomain: "jamitech-4440d.firebaseapp.com",
  projectId: "jamitech-4440d",
  storageBucket: "jamitech-4440d.appspot.com",
  messagingSenderId: "281815710475",
  appId: "1:281815710475:web:908ba0f938e7d6a28a800e",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
export { auth, db };
