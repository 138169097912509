import { onAuthStateChanged } from "firebase/auth";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import toast from "react-hot-toast";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";

const AdminGallery = () => {
  const [user, setUser] = useState("");
  const [gallery, setGallery] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUser(user);
      } else {
        alert("You are not Anuthorized");
      }
    });
  }, []);

  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      const packages = query(collection(db, "Gallery"));
      const querySnapshot = await getDocs(packages);
      querySnapshot.forEach((doc) => {
        topList.push({ id: doc.id, ...doc.data() });
      });

      setGallery(topList);
    };
    getData();
  }, []);

  const handleSubmit = async () => {
    const value = document.getElementById("img_link").value;
    const format = {
      src: value,
      width: 800,
      height: 600,
      srcSet: [
        {
          src: value,
        },
        {
          src: value,
        },
      ],
    };
    // console.log(format);
    if (value) {
      try {
        await addDoc(collection(db, "Gallery"), {
          format,
        });
        if (window.confirm("Image Added")) {
          navigate("/administrator");
        }
      } catch (err) {
        alert("Image not Added" + err);
        console.log(err);
      }
    } else {
      alert("please add a link");
    }
  };

  const handleDelete = async (e) => {
    const id = e.target.id;
    if (window.confirm("are you sure you want to delete this post?")) {
      try {
        await deleteDoc(doc(db, "Gallery", id));
        if (window.confirm("Image Removed")) {
          navigate("/administrator");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  if (user) {
    return (
      <>
        <div className=" empty-space">
          <div className="content-wrapper">
            <div className="sidebar">
              <br />
              <br />
              <div className="sidebar-header">
                <img
                  src="https://www.bsn.eu/wp-content/uploads/2016/12/user-icon-image-placeholder.jpg"
                  alt=""
                  className="logo"
                />
                <p className="online-status">
                  <img src="https://icon-library.com/images/online-status-icon/online-status-icon-25.jpg" />
                  Online
                </p>
                <p>{user.email}</p>
              </div>

              <div className="link-list">
                <ul className="links">
                  <li>
                    <Link to="/administrator/packages">Home</Link>
                  </li>
                  <li>
                    <Link to="/administrator/create-package">
                      Add Packages Single
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrator/create-package-mid-max">
                      Add Packages Double
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrator/packages">All Packages</Link>
                  </li>
                  <li>
                    <Link to="/administrator/gallery">Gallery</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="main content-box">
              <br />
              <br />
              <div className="main-content">
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Gallery Images
                </h2>
                <div className="image-form">
                  <input
                    placeholder="Paste image url to upload"
                    type="text"
                    id="img_link"
                  />
                  <button
                    className="btn_add"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Add Image
                  </button>
                </div>
                <br />
                <br />
                <div className="gallery-container">
                  {gallery?.map((item, index) => (
                    <div key={index} className="gallery-items">
                      <div className="single-image">
                        <img src={item.format.src} />
                      </div>
                      <div className="action">
                        <button
                          className="dng"
                          id={item.id}
                          onClick={handleDelete}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="not-auth empty-space">
          <h1>You are not Authorized</h1>
          <Link to="/">
            <button className="btn-secondary">Go Back</button>
          </Link>
        </div>
      </>
    );
  }
};

export default AdminGallery;
