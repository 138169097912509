import React, { useEffect, useState } from "react";
import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const Tubular = () => {
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      const q = query(
        collection(db, "Packages"),
        where("batteryType", "==", "Tubular")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        topList.push({ id: doc.id, ...doc.data() });
      });
      setPackages(topList);
    };
    getData();
  }, []);
  // console.log(packages);
  return (
    <div>
      <br />
      <Hero hero="roomsHerot">
        <Banner title="Tubular battery Packages">
          <Link to="/packages" className="btn-primary">
            Return to packages
          </Link>
        </Banner>
      </Hero>
      <br /> <br />
      <header class="section-header">
        <h3>Explore our Tubular Battery Packages</h3>
        <div class="divider"></div>
      </header>
      <section className="package-container">
        <div class="overview-content">
          <ul class="features-list">
            {packages.map((item, index) => (
              <li key={index}>
                <Link to={"/packages/" + item.id}>
                  <span>{item.packages}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <br />
      </section>
    </div>
  );
};

export default Tubular;
