import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import toast from "react-hot-toast";

const AdminAllPackages = () => {
  const [user, setUser] = useState("");
  const [packages, setPackages] = useState([]);
  // const packages = [{ 1: 2 }, { 2: 67 }];
  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      const Packages = query(
        collection(db, "Packages"),
        orderBy("date", "desc")
      );
      const querySnapshot = await getDocs(Packages);
      querySnapshot.forEach((doc) => {
        topList.push({ id: doc.id, ...doc.data() });
      });

      setPackages(topList);
    };
    getData();
  }, []);
  const navigate = useNavigate();
  //console.log(packages);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        console.log(user);
        setUser(user);
      } else {
        alert("You are not Anuthorized");
      }
    });
  }, []);

  const handleLinkDelete = async (id) => {
    console.log(id);
    try {
      await deleteDoc(doc(db, "Packages", id));
      navigate("/administrator/packages");
    } catch (err) {
      // console.log(err);
    }
  };

  if (user) {
    return (
      <>
        <div className=" empty-space">
          <div className="content-wrapper">
            <div className="sidebar">
              <br />
              <br />
              <div className="sidebar-header">
                <img
                  src="https://www.bsn.eu/wp-content/uploads/2016/12/user-icon-image-placeholder.jpg"
                  alt=""
                  className="logo"
                />
                <p className="online-status">
                  <img src="https://icon-library.com/images/online-status-icon/online-status-icon-25.jpg" />
                  Online
                </p>
                <p>{user.email}</p>
              </div>

              <div className="link-list">
                <ul className="links">
                  <li>
                    <Link to="/administrator/packages">Home</Link>
                  </li>
                  <li>
                    <Link to="/administrator/create-package">
                      Add Packages Single
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrator/create-package-mid-max">
                      Add Packages Double
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrator/packages">All Packages</Link>
                  </li>
                  <li>
                    <Link to="/administrator/gallery">Gallery</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="main content-box">
              <br />
              <br />
              <div className="main-content">
                <h2 style={{ color: "white", textAlign: "center" }}>
                  All package
                </h2>

                <div class="overview-content admin">
                  <ul class="features-list">
                    {packages.map((item, index) => (
                      <li key={index}>
                        {item.double ? (
                          <>
                            <Link to={"/administrator/edit-mid-max/" + item.id}>
                              <span>{item.packages}</span>
                            </Link>

                            <img
                              onClick={() => handleLinkDelete(item.id)}
                              src="https://cdn-icons-png.flaticon.com/512/401/401036.png"
                            />
                          </>
                        ) : (
                          <>
                            <Link to={"/administrator/edit/" + item.id}>
                              <span>{item.packages}</span>
                            </Link>

                            <img
                              onClick={() => handleLinkDelete(item.id)}
                              src="https://cdn-icons-png.flaticon.com/512/401/401036.png"
                            />
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="not-auth empty-space">
          <h1>You are not Authorized</h1>
          <Link to="/">
            <button className="btn-secondary">Go Back</button>
          </Link>
        </div>
      </>
    );
  }
};

export default AdminAllPackages;
