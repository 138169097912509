import { onAuthStateChanged } from "firebase/auth";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { auth, db } from "../firebase";
const initialState = {
  packagesMid: "",
  packages: "",
  packagesMax: "",
  priceWSolarMid: "",
  priceWOSolarMid: "",
  priceWSolarMax: "",
  priceWOSolarMax: "",
  batteryType: "",
};
const AdminAddPackageMidMax = () => {
  const [user, setUser] = useState("");
  const [form, setForm] = useState(initialState);
  const [specificationMid, setSpecificationMid] = useState();
  const [applianciesMid, setApplianciesMid] = useState();
  const [durationMax, setDurationMax] = useState();
  const [durationMid, setDurationMid] = useState();
  const [specificationMax, setSpecificationMax] = useState();
  const [applianciesMax, setApplianciesMax] = useState();
  const [description, setDescription] = useState();

  const navigate = useNavigate();
  const {
    packages,
    packagesMid,
    packagesMax,
    priceWSolarMid,
    priceWOSolarMid,
    priceWSolarMax,
    priceWOSolarMax,
  } = form;
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUser(user);
      } else {
        alert("You are not Anuthorized");
      }
    });
  }, []);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newData = {
      ...form,
      description,
      specificationMax,
      specificationMid,
      durationMax,
      durationMid,
      applianciesMax,
      applianciesMid,
    };
    console.log(newData);
    if (description) {
      try {
        await addDoc(collection(db, "Packages"), {
          ...newData,
          double: true,
          url: packages.split(" ")[0].toLocaleLowerCase(),
          date: serverTimestamp(),
        });
        // toast.success("Package Created Successfully");
      } catch (err) {
        // toast.error("Not Added" + err);
        console.log(err);
      }
    }
    navigate("/administrator/packages");
  };

  return (
    <>
      <div className=" empty-space">
        <div className="content-wrapper">
          <div className="sidebar">
            <br />
            <br />
            <div className="sidebar-header">
              <img
                src="https://www.bsn.eu/wp-content/uploads/2016/12/user-icon-image-placeholder.jpg"
                alt=""
                className="logo"
              />
              <p className="online-status">
                <img src="https://icon-library.com/images/online-status-icon/online-status-icon-25.jpg" />
                Online
              </p>
              <p>{user.email}</p>
            </div>

            <div className="link-list">
              <ul className="links">
                <li>
                  <Link to="/administrator/packages">Home</Link>
                </li>
                <li>
                  <Link to="/administrator/create-package">
                    Add Packages Single
                  </Link>
                </li>
                <li>
                  <Link to="/administrator/create-package-mid-max">
                    Add Packages Double
                  </Link>
                </li>
                <li>
                  <Link to="/administrator/packages">All Packages</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="main content-box">
            <br />
            <br />
            <div className="main-content">
              <h2 style={{ color: "white", textAlign: "center" }}>
                Mid Package
              </h2>

              <div className="form">
                <form className="package-form" onSubmit={handleSubmit}>
                  <label htmlFor="name">Main Package Name</label>
                  <input
                    type="text"
                    name="packages"
                    autoComplete="off"
                    placeholder="
Silver Package (3.5kva) MID and MAX"
                    value={packages}
                    onChange={handleChange}
                  />
                  <label htmlFor="name">Package Name</label>
                  <input
                    type="text"
                    name="packagesMid"
                    autoComplete="off"
                    placeholder="
Silver Package (3.5kva) MID"
                    value={packagesMid}
                    onChange={handleChange}
                  />

                  <label htmlFor="batteryType">Battery Type</label>
                  <select
                    style={{ margin: "10px 0", height: "40px" }}
                    id="bateryType"
                    onChange={handleChange}
                    name="batteryType"
                  >
                    <option value="">Select battery</option>
                    <option value="Lithium">Lithium</option>
                    <option value="Tubular">Tubular</option>
                  </select>

                  <label htmlFor="name">Specifications</label>
                  <ReactQuill
                    theme="snow"
                    type="text"
                    name="specification"
                    autoComplete="off"
                    value={specificationMid}
                    onChange={setSpecificationMid}
                  />
                  <label htmlFor="name">Appliancies</label>
                  <ReactQuill
                    theme="snow"
                    type="text"
                    name="appliancies"
                    autoComplete="off"
                    value={applianciesMid}
                    onChange={setApplianciesMid}
                  />
                  <label htmlFor="name">Backup Duration</label>
                  <ReactQuill
                    theme="snow"
                    type="text"
                    name="duration"
                    autoComplete="off"
                    value={durationMid}
                    onChange={setDurationMid}
                  />

                  <div className="row">
                    <div className="col">
                      <div className="">
                        <label htmlFor="name">Price with Solar</label>
                        <input
                          type="text"
                          name="priceWSolarMid"
                          autoComplete="off"
                          value={priceWSolarMid}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="">
                        <label htmlFor="name">Price without Solar</label>
                        <input
                          type="text"
                          name="priceWOSolarMid"
                          autoComplete="off"
                          value={priceWOSolarMid}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <h2 style={{ color: "white", textAlign: "center" }}>
                    Max Package
                  </h2>

                  <label htmlFor="name">Package Name</label>
                  <input
                    type="text"
                    name="packagesMax"
                    autoComplete="off"
                    placeholder="
Silver Package (3.5kva) MAX"
                    value={packagesMax}
                    onChange={handleChange}
                  />
                  <label htmlFor="name">Specifications</label>
                  <ReactQuill
                    theme="snow"
                    type="text"
                    name="specification"
                    autoComplete="off"
                    value={specificationMax}
                    onChange={setSpecificationMax}
                  />
                  <label htmlFor="name">Appliancies</label>
                  <ReactQuill
                    theme="snow"
                    type="text"
                    name="appliancies"
                    autoComplete="off"
                    value={applianciesMax}
                    onChange={setApplianciesMax}
                  />
                  <label htmlFor="name">Backup Duration</label>
                  <ReactQuill
                    theme="snow"
                    type="text"
                    name="duration"
                    autoComplete="off"
                    value={durationMax}
                    onChange={setDurationMax}
                  />
                  <label htmlFor="name">Description</label>
                  <ReactQuill
                    type="text"
                    name="description"
                    autoComplete="off"
                    value={description}
                    onChange={setDescription}
                  />
                  <div className="row">
                    <div className="col">
                      <div className="">
                        <label htmlFor="name">Price with Solar</label>
                        <input
                          type="text"
                          name="priceWSolarMax"
                          autoComplete="off"
                          value={priceWSolarMax}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="">
                        <label htmlFor="name">Price without Solar</label>
                        <input
                          type="text"
                          name="priceWOSolarMax"
                          autoComplete="off"
                          value={priceWOSolarMax}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <button className="btn-secondary" type="submit">
                    ADD
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAddPackageMidMax;
