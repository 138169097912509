import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { auth, db } from "../firebase";
const initialState = {
  packagesMid: "",
  packages: "",
  packagesMax: "",
  priceWSolarMid: "",
  priceWOSolarMid: "",
  priceWSolarMax: "",
  priceWOSolarMax: "",
  batteryType: "",
};

const AdminEditPackageMidMax = () => {
  const { id } = useParams();
  const [user, setUser] = useState("");
  const [form, setForm] = useState(initialState);
  const [specificationMid, setSpecificationMid] = useState();
  const [applianciesMid, setApplianciesMid] = useState();
  const [durationMax, setDurationMax] = useState();
  const [durationMid, setDurationMid] = useState();
  const [specificationMax, setSpecificationMax] = useState();
  const [applianciesMax, setApplianciesMax] = useState();
  const [description, setDescription] = useState();
  const navigate = useNavigate();
  const {
    packages,
    packagesMid,
    packagesMax,
    priceWSolarMid,
    priceWOSolarMid,
    priceWSolarMax,
    priceWOSolarMax,
    batteryType,
  } = form;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUser(user);
      } else {
        alert("You are not Anuthorized");
      }
    });
  }, []);

  useEffect(() => {
    id && getPackageDetails();
  }, [id]);
  const getPackageDetails = async () => {
    const docRef = doc(db, "Packages", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setForm({ ...snapshot.data() });
      setDescription(snapshot.data().description);
      setApplianciesMax(snapshot.data().applianciesMax);
      setApplianciesMid(snapshot.data().applianciesMid);
      setSpecificationMax(snapshot.data().specificationMax);
      setSpecificationMid(snapshot.data().specificationMid);
      setDurationMax(snapshot.data().durationMax);
      setDurationMid(snapshot.data().durationMid);
    }
  };
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const newData = {
    ...form,
    durationMax,
    durationMid,
    specificationMax,
    specificationMid,
    applianciesMax,
    applianciesMid,
    description,
    url: packages.split(" ")[0].toLocaleLowerCase(),
  };

  const handleSubmit = async (e) => {
    console.log(newData);
    e.preventDefault();
    try {
      await updateDoc(doc(db, "Packages", id), {
        ...newData,
      });
      toast.success("Package Updated Successfully");
    } catch (err) {
      console.log(err);
    }

    // navigate("/admin");
    navigate("/administrator/packages");
  };
  if (user) {
    return (
      <>
        <div className=" empty-space">
          <div className="content-wrapper">
            <div className="sidebar">
              <br />
              <br />
              <div className="sidebar-header">
                <img
                  src="https://www.bsn.eu/wp-content/uploads/2016/12/user-icon-image-placeholder.jpg"
                  alt=""
                  className="logo"
                />
                <p className="online-status">
                  <img src="https://icon-library.com/images/online-status-icon/online-status-icon-25.jpg" />
                  Online
                </p>
                <p>{user.email}</p>
              </div>

              <div className="link-list">
                <ul className="links">
                  <li>
                    <Link to="/administrator/packages">Home</Link>
                  </li>
                  <li>
                    <Link to="/administrator/create-package">
                      Add Packages Single
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrator/create-package-mid-max">
                      Add Packages Double
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrator/packages">All Packages</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="main content-box">
              <br />
              <br />
              <div className="main-content">
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Mid Package
                </h2>

                <div className="form">
                  <form className="package-form" onSubmit={handleSubmit}>
                    <label htmlFor="batteryType">Battery Type</label>
                    <select
                      style={{ margin: "10px 0", height: "40px" }}
                      id="bateryType"
                      onChange={handleChange}
                      name="batteryType"
                    >
                      <option value="">Select battery</option>
                      <option value="Lithium">Lithium</option>
                      <option value="Tubular">Tubular</option>
                    </select>

                    <label htmlFor="name">Main Package Name</label>
                    <input
                      type="text"
                      name="packages"
                      autoComplete="off"
                      placeholder="
Silver Package (3.5kva) MID and MAX"
                      value={packages}
                      onChange={handleChange}
                    />
                    <label htmlFor="name">Package Name</label>
                    <input
                      type="text"
                      name="packagesMid"
                      autoComplete="off"
                      placeholder="
Silver Package (3.5kva) MID"
                      value={packagesMid}
                      onChange={handleChange}
                    />
                    <label htmlFor="name">Specifications</label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      name="specification"
                      autoComplete="off"
                      value={specificationMid}
                      onChange={setSpecificationMid}
                    />
                    <label htmlFor="name">Appliancies</label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      name="appliancies"
                      autoComplete="off"
                      value={applianciesMid}
                      onChange={setApplianciesMid}
                    />
                    <label htmlFor="name">Backup Duration</label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      name="duration"
                      autoComplete="off"
                      value={durationMid}
                      onChange={setDurationMid}
                    />

                    <div className="row">
                      <div className="col">
                        <div className="">
                          <label htmlFor="name">Price with Solar</label>
                          <input
                            type="text"
                            name="priceWSolarMid"
                            autoComplete="off"
                            value={priceWSolarMid}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="">
                          <label htmlFor="name">Price without Solar</label>
                          <input
                            type="text"
                            name="priceWOSolarMid"
                            autoComplete="off"
                            value={priceWOSolarMid}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <h2 style={{ color: "white", textAlign: "center" }}>
                      Max Package
                    </h2>

                    <label htmlFor="name">Package Name</label>
                    <input
                      type="text"
                      name="packagesMax"
                      autoComplete="off"
                      placeholder="
Silver Package (3.5kva) MAX"
                      value={packagesMax}
                      onChange={handleChange}
                    />
                    <label htmlFor="name">Specifications</label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      name="specification"
                      autoComplete="off"
                      value={specificationMax}
                      onChange={setSpecificationMax}
                    />
                    <label htmlFor="name">Appliancies</label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      name="appliancies"
                      autoComplete="off"
                      value={applianciesMax}
                      onChange={setApplianciesMax}
                    />
                    <label htmlFor="name">Backup Duration</label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      name="duration"
                      autoComplete="off"
                      value={durationMax}
                      onChange={setDurationMax}
                    />
                    <label htmlFor="name">Description</label>
                    <ReactQuill
                      type="text"
                      name="description"
                      autoComplete="off"
                      value={description}
                      onChange={setDescription}
                    />
                    <div className="row">
                      <div className="col">
                        <div className="">
                          <label htmlFor="name">Price with Solar</label>
                          <input
                            type="text"
                            name="priceWSolarMax"
                            autoComplete="off"
                            value={priceWSolarMax}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="">
                          <label htmlFor="name">Price without Solar</label>
                          <input
                            type="text"
                            name="priceWOSolarMax"
                            autoComplete="off"
                            value={priceWOSolarMax}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <button className="btn-secondary" type="submit">
                      ADD
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="not-auth empty-space">
          <h1>You are not Authorized</h1>
          <Link to="/">
            <button className="btn-secondary">Go Back</button>
          </Link>
        </div>
      </>
    );
  }
};

export default AdminEditPackageMidMax;
