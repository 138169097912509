import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Banner from "../../components/Banner";
import Hero from "../../components/Hero";
import { db } from "../../firebase";

const PackageDetails = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    id && getPackageDetails();
  }, [id]);

  const getPackageDetails = async () => {
    const docRef = doc(db, "Packages", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const newUrl = data;
  console.log(newUrl);
  if (data.double) {
    return (
      <>
        <Hero hero="roomsHero">
          <Banner title={data.packages}>
            <Link to="/packages" className="btn-primary">
              See All Packages
            </Link>
          </Banner>
        </Hero>
        <br />
        <div className="container">
          <div className="packages-details">
            <div class="packages-header">{data.packagesMid}</div>
            <div class="packages-content row">
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Specification</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: data.specificationMid }}
                />
              </div>
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Appliances</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: data.applianciesMid }}
                />
              </div>
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Backup duration:</h3>
                <div dangerouslySetInnerHTML={{ __html: data.durationMid }} />
              </div>
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Prices</h3>
                <li>
                  {data.priceWOSolarMid} <br />
                  <a href={"/quote?" + data.url}>(Get Package)</a>
                </li>
                <li>
                  {data.priceWSolarMid} <br />
                  <a href={"/quote?" + data.url}>(Get Package)</a>
                </li>
              </div>
            </div>
            <br />
            <div class="packages-header">{data.packagesMax}</div>
            <div class="packages-content row">
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Specification</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: data.specificationMax }}
                />
              </div>
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Appliances</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: data.applianciesMax }}
                />
              </div>
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Backup duration:</h3>
                <div dangerouslySetInnerHTML={{ __html: data.durationMax }} />
              </div>
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Prices</h3>
                <li>
                  {data.priceWOSolarMax} <br />
                  <a
                    href={
                      "/quote?" +
                      "product=" +
                      data.packages +
                      "&battery-type=" +
                      data.batteryType +
                      "&solar=false"
                    }
                  >
                    (Get Package)
                  </a>
                </li>
                <li>
                  {data.priceWSolarMax} <br />
                  <a href={"/quote?" + data.url}>(Get Package)</a>
                </li>
              </div>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Hero hero="roomsHero">
          <Banner title={data.packages}>
            <Link to="/packages" className="btn-primary">
              See All Packages
            </Link>
          </Banner>
        </Hero>
        <br />
        <div className="container">
          <div className="packages-details">
            <div class="packages-header">{data.packages}</div>
            <div class="packages-content row">
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Specification</h3>
                <div dangerouslySetInnerHTML={{ __html: data.specification }} />
              </div>
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Appliances</h3>
                <div dangerouslySetInnerHTML={{ __html: data.appliancies }} />
              </div>
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Backup duration:</h3>
                <div dangerouslySetInnerHTML={{ __html: data.duration }} />
              </div>
              <div class="p-5 col-xs-12 col-md-3 col-lg-3">
                <h3>Prices</h3>
                <li>
                  {data.priceWOSolar} <br />
                  <a href={"/quote?" + data.url}>(Get Package)</a>
                </li>
                <li>
                  {data.priceWSolar} <br />
                  <a href={"/quote?" + data.url}>(Get Package)</a>
                </li>
              </div>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
      </>
    );
  }
};

export default PackageDetails;
