import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, Redirect, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
// import { Navigate } from "react-router-dom";
// import { toast } from "react-toastify";
const initialState = {
  packages: "",
  specification: "",
  appliances: "",
  duration: "",
  priceWSolar: "",
  priceWOSolar: "",
  description: "",
  batteryType: "",
};
const AdminAddPackage = () => {
  const [user, setUser] = useState("");
  const [form, setForm] = useState(initialState);
  const [description, setDescription] = useState();
  const [specification, setSpecification] = useState();
  const [appliancies, setAppliancies] = useState();
  const [duration, setDuration] = useState();
  const navigate = useNavigate();

  const { packages, priceWSolar, priceWOSolar } = form;
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUser(user);
      } else {
        alert("You are not Anuthorized");
      }
    });
  }, []);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (packages && description && specification) {
      try {
        await addDoc(collection(db, "Packages"), {
          ...form,
          description,
          specification,
          appliancies,
          duration,
          url: packages.split(" ")[0].toLocaleLowerCase(),
          userId: user.uid,
          date: serverTimestamp(),
        });

        toast.success("Package Created Successfully");
      } catch (err) {
        toast.error("Not Added" + err);
        console.log(err);
      }
    }
    navigate("/administrator/packages");
    console.log(form);
  };

  if (user) {
    return (
      <>
        <div className=" empty-space">
          <div className="content-wrapper">
            <div className="sidebar">
              <br />
              <br />
              <div className="sidebar-header">
                <img
                  src="https://www.bsn.eu/wp-content/uploads/2016/12/user-icon-image-placeholder.jpg"
                  alt=""
                  className="logo"
                />
                <p className="online-status">
                  <img src="https://icon-library.com/images/online-status-icon/online-status-icon-25.jpg" />
                  Online
                </p>
                <p>{user.email}</p>
              </div>

              <div className="link-list">
                <ul className="links">
                  <li>
                    <Link to="/administrator/packages">Home</Link>
                  </li>
                  <li>
                    <Link to="/administrator/create-package">
                      Add Packages Single
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrator/create-package-mid-max">
                      Add Packages Double
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrator/packages">All Packages</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="main content-box">
              <br />
              <br />
              <div className="main-content">
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Create a package
                </h2>

                <div className="form">
                  <form className="package-form" onSubmit={handleSubmit}>
                    <label htmlFor="name">Package Name</label>
                    <input
                      type="text"
                      name="packages"
                      autoComplete="off"
                      value={packages}
                      onChange={handleChange}
                    />
                    <label htmlFor="batteryType">Battery Type</label>
                    <select
                      style={{ margin: "10px 0", height: "40px" }}
                      id="bateryType"
                      onChange={handleChange}
                      name="batteryType"
                    >
                      <option value="">Select battery</option>
                      <option value="Lithium">Lithium</option>
                      <option value="Tubular">Tubular</option>
                    </select>

                    <label htmlFor="name">Specifications</label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      name="specification"
                      autoComplete="off"
                      value={specification}
                      onChange={setSpecification}
                    />
                    <label htmlFor="name">Appliancies</label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      name="appliancies"
                      autoComplete="off"
                      value={appliancies}
                      onChange={setAppliancies}
                    />
                    <label htmlFor="name">Backup Duration</label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      name="duration"
                      autoComplete="off"
                      value={duration}
                      onChange={setDuration}
                    />
                    <label htmlFor="name">Description</label>
                    <ReactQuill
                      type="text"
                      name="description"
                      autoComplete="off"
                      value={description}
                      onChange={setDescription}
                    />
                    <div className="row">
                      <div className="col">
                        <div className="">
                          <label htmlFor="name">Price with Solar</label>
                          <input
                            type="text"
                            name="priceWSolar"
                            autoComplete="off"
                            value={priceWSolar}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="">
                          <label htmlFor="name">Price without Solar</label>
                          <input
                            type="text"
                            name="priceWOSolar"
                            autoComplete="off"
                            value={priceWOSolar}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <button className="btn-secondary" type="submit">
                      ADD
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="not-auth empty-space">
          <h1>You are not Authorized</h1>
          <Link to="/">
            <button className="btn-secondary">Go Back</button>
          </Link>
        </div>
      </>
    );
  }
};

export default AdminAddPackage;
