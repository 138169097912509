import { React, useState } from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useEffect } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";

const Gallery = () => {
  const [index, setIndex] = useState(-1);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      const packages = query(collection(db, "Gallery"));
      const querySnapshot = await getDocs(packages);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().format });
      });

      setPhotos(topList);
    };
    getData();
  }, []);

  // console.log(photos);
  return (
    <>
      <Hero hero="roomsHero">
        <Banner title="Our Gallery">
          <Link to="/" className="btn-primary">
            Return Home
          </Link>
        </Banner>
      </Hero>
      <div className="gallery-container">
        <br />
        <div id="gallery">
          {photos?.map((item, index) => (
            <img src={item.src} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Gallery;
